import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { devices, sizes } from '../../assets/devices';
import theme from '../../assets/theme';
import { useNavs } from '../../hooks/useNavs';
import { useRevelaer } from '../../hooks/useRevealer';
import PulsingButton from '../pulsingButton';
import Typography from '../typography';

const Footer = ({
  title,
  url,
  isTwoLines,
}: {
  title: string;
  url: string;
  isTwoLines?: boolean;
}) => {
  const navigate = useNavigate();
  const reveal = useRevelaer();
  const { navs } = useNavs();

  let currentUrlIndex = navs.findIndex((nav) => nav.active);
  let targetUrlIndex = navs.findIndex((nav) => nav.url === url);

  return (
    <Box
      height="100vh"
      paddingTop="389px"
      sx={{
        backgroundColor: theme.palette.primary.dark,
        [devices.iPad]: {
          paddingTop: '300px',
        },
      }}
    >
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            mt={2}
            maxWidth={window.innerWidth <= sizes.iPad ? '575px' : '868px'}
          >
            <Typography
              color="secondary.light"
              variant="body1"
              sx={{ marginBottom: '20px' }}
            >
              Nächstes Thema
            </Typography>

            <Typography color="secondary.light" variant="h2" fontWeight={400}>
              {title}
            </Typography>
          </Box>

          <Box paddingLeft={9}>
            <PulsingButton
              onClick={() => {
                reveal(
                  currentUrlIndex > targetUrlIndex ? 'left' : 'right',
                  () => {
                    navigate(url);
                  }
                );
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  color="primary.dark"
                  fontWeight={700}
                  variant="body1"
                >
                  Jetzt
                </Typography>

                <Typography
                  color="primary.dark"
                  fontWeight={700}
                  variant="body1"
                >
                  weiterlesen
                </Typography>
              </Box>
            </PulsingButton>
          </Box>

          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
