import { useParams } from 'react-router';

export function useNavs() {
  let { topic } = useParams();

  const navs = [
    {
      title: 'Mehr Dienstrad',
      active: topic === '1',
      url: '/1#JobBikesSince2012',
    },
    {
      title: 'Mehr Erfolgsgeschichten',
      active: topic === '2',
      url: '/2#Logos',
    },
    {
      title: 'Mehr Service',
      active: topic === '3',
      url: '/3#Impact',
    },
    { title: 'Mehr Gesundheit', active: topic === '4', url: '/4#SickDays' },
    {
      title: 'Mehr Nachhaltigkeit',
      active: topic === '5',
      url: '/5#SavesCO2',
    },
  ];

  return { navs };
}
