import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { devices } from '../../assets/devices';
import theme from '../../assets/theme';
import FillingText from '../../components/fillingText';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const Carefree = () => {
  const [isActiveSession] = useActiveSession('#Carefree');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Box
          maxWidth="1400px"
          sx={{
            [theme.breakpoints.down('xl')]: {
              maxWidth: '1100px',
            },
          }}
        >
          {showText && (
            <Typography
              color="primary.dark"
              variant="h4"
              sx={{ lineHeight: '115%', [devices.iPad]: { fontSize: '74px' } }}
            >
              <FillingText>
                Mitarbeitende fahren unbeschwert dank Vollkaskoversicherung
                inklusive Mobilitätsgarantie. Auf Wunsch bieten
                JobRad-FullService oder JobRad-Inspektion zusätzlichen Komfort.
              </FillingText>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
