import { Box } from '@mui/system';
import theme from '../../assets/theme';
import Typography from '../../components/typography';

type Props = {
  image: string;
  text: string;
  name: string;
  subtitle: string;
  background: 'primary.main' | 'primary.dark' | 'secondary.main';
};

export const Quote = ({ image, text, name, subtitle, background }: Props) => {
  const textColor =
    background === 'primary.dark' ? 'secondary.light' : 'primary.dark';
  const infoTextColor =
    background === 'primary.dark' ? 'primary' : 'primary.dark';

  return (
    <Box
      sx={{
        backgroundColor: (theme.palette as any)[background],
      }}
    >
      <Box
        paddingLeft={10}
        paddingRight={11}
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <img
            src={image}
            alt={`Image of ${name}`}
            style={{ maxWidth: '280px', borderRadius: '100%' }}
          />
        </Box>

        <Box marginLeft={5}>
          <Typography
            variant="h5"
            color={textColor}
            sx={{ maxWidth: '1310px' }}
          >
            {text}
          </Typography>

          <Box mt={3}>
            <Typography variant="h6" fontWeight={700} color={infoTextColor}>
              {name},
            </Typography>
            <Typography variant="h6" fontWeight={700} color={infoTextColor}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
