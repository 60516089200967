import { Box } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie-player';
import Logo from '../../assets/images/darkLogo.svg';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';

export const Impact = () => {
  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={6} marginTop={6} marginBottom={9}>
        <img src={Logo} alt="Jobrad Logo" />
      </Box>
      <Box height="100%" display="flex" alignItems="center" paddingLeft="80px">
        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>
        <Box marginTop={6} sx={{ '&>*': { lineHeight: '140%' } }}>
          <Typography variant="h5" color="primary.dark" fontWeight={700}>
            Minimaler Aufwand.
          </Typography>
          <Typography variant="h5" color="primary.dark" fontWeight={700}>
            Maximaler Impact.
          </Typography>
          <Typography variant="h5" color="primary.dark">
            Alles digital, schnell und unkompliziert.
          </Typography>
          <Typography variant="h5" color="primary.dark">
            Mit dem leistungsstärksten Admin-Portal
          </Typography>
          <Typography variant="h5" color="primary.dark">
            und dem größten Support-Team am Markt.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
