import ReactFullpage from '@fullpage/react-fullpage';
import { Box } from '@mui/material';
import React from 'react';
import Img from '../../../assets/images/alexander.jpg';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import {
  MiracleCure,
  Quote,
  ReducesRiskHeartAttack,
  SickDays,
} from '../../../sections';

const B2CTopic4 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'SickDays',
      'Quote',
      'ReducesRiskHeartAttack',
      'MiracleCure',
      'MiracleCure',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <SickDays />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Quote
              image={Img}
              name="Alexander Eckhardt"
              subtitle="Projektleiter bei SAP Flexible Mobility"
              text={
                '„Wir erhalten tolle Rückmeldungen von Kolleginnen und Kollegen, die mit ihrem SAP-JobRad überall unterwegs sind – oder sich sogar schon das zweite Dienstrad zugelegt haben.”'
              }
              background="primary.main"
            />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <ReducesRiskHeartAttack />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <MiracleCure />
          </Box>

          <Box className="section">
            <Footer title="Mehr Nachhaltigkeit" url="/5#SavesCO2" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic4;
