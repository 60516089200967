import { Box } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const CompanyBikeLeasing = () => {
  const [isActiveSession] = useActiveSession('#CompanyBikeLeasing');

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} marginTop={6}>
        <Typography
          color="primary.dark"
          variant="h1"
          sx={{ marginBottom: '48px' }}
        >
          {isActiveSession ? (
            <CountUp start={0} end={56} duration={2} separator="." />
          ) : (
            '56'
          )}
          %
        </Typography>

        <Typography color="primary.dark" variant="h5">
          der JobRadler:innen haben sich dank
        </Typography>

        <Typography color="primary.dark" variant="h5">
          Dienstradleasing ein Fahrrad zugelegt, das
        </Typography>

        <Typography color="primary.dark" variant="h5">
          sie sich sonst nicht leisten würden.
        </Typography>
      </Box>
    </Box>
  );
};
