import ReactFullpage from '@fullpage/react-fullpage';
import { Box } from '@mui/material';
import React from 'react';
import Img from '../../../assets/images/christian.jpg';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import {
  FiveMillionEmployees,
  JobBikesIn2022,
  LogoFarm,
  Logos,
  Quote,
} from '../../../sections';

const B2CTopic2 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'Logos',
      'FiveMillionEmployees',
      'JobBikesIn2022',
      'Quote',
      'LogoFarm',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Logos />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <FiveMillionEmployees />
          </Box>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <JobBikesIn2022 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Quote
              image={Img}
              name="Christian Harms"
              subtitle="Geschäftsführer und verantwortlich für das Ressort Mitarbeiter bei dm"
              text={
                '„Mit JobRad® aus Freiburg haben wir einen kompetenten und erfahrenen Partner gefunden, der für jeden unserer Mitarbeiter ein passendes Rad im Angebot hat.” '
              }
              background="secondary.main"
            />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <LogoFarm />
          </Box>

          <Box className="section">
            <Footer title="Mehr Service" url="/3#Impact" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic2;
