import { Box } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import Lottie from 'react-lottie-player';
import Logo from '../../assets/images/logo.svg';
import DragIndicator from '../../assets/lottie/dragIndicator.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const SickDays = () => {
  const [isActiveSession] = useActiveSession('#SickDays');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
      <Box paddingLeft={6} marginTop={6} marginBottom={9}>
        <img src={Logo} alt="Jobrad Logo" />
      </Box>

      <Box paddingLeft={10}>
        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>

        <Typography variant="h1" sx={{ marginBottom: '48px' }}>
          -
          {isActiveSession ? (
            <CountUp start={0} end={2} duration={2} separator="." />
          ) : (
            '2'
          )}
        </Typography>

        <Typography variant="h5">
          Regelmäßige JobRadlader:innen sind im Schnitt zwei
        </Typography>

        <Typography variant="h5">Tage weniger krank pro Jahr.</Typography>
      </Box>
    </Box>
  );
};
