import React from 'react';
import { Box } from '@mui/system';
import theme from '../../assets/theme';
import { Typography } from '@mui/material';
import Img from '../../assets/images/lowerCosts.svg';
import { devices } from '../../assets/devices';

export const LowerCosts = () => (
  <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
    <Box ml={20} mt={16}>
      <Typography variant="h5" color="secondary.light" fontWeight={400}>
        Weniger Kosten für
      </Typography>

      <Typography variant="h5" color="secondary.light" fontWeight={400}>
        Infrastruktur und Klima
      </Typography>

      <Box
        mt={10}
        sx={{
          [devices.iPad]: {
            marginTop: 0,
            width: '800px',
          },
        }}
      >
        <img
          src={Img}
          alt="Lower Costs for Infrastructure"
          className="ipadFull"
        />
      </Box>
    </Box>
  </Box>
);
