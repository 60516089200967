import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box } from '@mui/material';
import IdleMode from './idleMode';
import { useIdleTimer } from 'react-idle-timer';
import IdleContext from '../context/idleContext';
import B2CTopic1 from './b2c/topic1';
import B2CTopic2 from './b2c/topic2';
import B2CTopic3 from './b2c/topic3';
import B2CTopic4 from './b2c/topic4';
import Wrapper from '../components/wrapper';
import B2CTopic5 from './b2c/topic5';

function FullPage() {
  let { topic } = useParams();
  const navigate = useNavigate();
  const idleContext = useContext(IdleContext);
  const { isIdle, setIdle } = idleContext;

  const switchTopic = () => {
    switch (topic) {
      case '1':
        return <B2CTopic1 />;
      case '2':
        return <B2CTopic2 />;
      case '3':
        return <B2CTopic3 />;
      case '4':
        return <B2CTopic4 />;
      case '5':
        return <B2CTopic5 />;
      default:
        return <B2CTopic1 />;
    }
  };

  const onIdle = () => {
    setIdle(true);
  };

  useIdleTimer({
    onIdle,
    timeout: 30_000,
    throttle: 500,
  });

  const actionIdleMode = () => {
    navigate('/1#JobBikesSince2012');
    setIdle(false);
  };

  useEffect(() => {
    switchTopic();
  }, []);

  return (
    <Box>
      {isIdle ? (
        <IdleMode action={() => actionIdleMode()} />
      ) : (
        <Box>
          <Wrapper />
          <Box>{switchTopic()}</Box>
        </Box>
      )}
    </Box>
  );
}

export default FullPage;
