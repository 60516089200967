import { Box } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const Employees = () => {
  const [isActiveSession] = useActiveSession('#Employees');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={10} marginTop={18}>
        <Typography variant="h2" color="primary.dark" fontWeight={700}>
          +{' '}
          {isActiveSession ? (
            <CountUp start={0} end={800} duration={2} separator="." />
          ) : (
            '800'
          )}
        </Typography>

        <Typography color="primary.dark" variant="h3">
          JobRad-Mitarbeitende sorgen
        </Typography>

        <Typography color="primary.dark" variant="h3">
          für eine reibungslose
        </Typography>

        <Typography color="primary.dark" variant="h3">
          Abwicklung aller Prozesse.
        </Typography>
      </Box>
    </Box>
  );
};
