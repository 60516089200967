import { Box } from '@mui/material';
import Img from '../../assets/images/logoFarm.png';
import theme from '../../assets/theme';
import Typography from '../../components/typography';

export const LogoFarm = () => (
  <Box sx={{ backgroundColor: theme.palette.primary.main }}>
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column" paddingLeft="80px">
        <Typography variant="h5" color="primary.dark">
          In bester Gesellschaft. <b>Mehr als 70.000</b>
        </Typography>

        <Typography variant="h5" color="primary.dark" fontWeight={700}>
          Arbeitgeber:innen bieten bereits JobRad® an.
        </Typography>
      </Box>

      <Box
        marginTop={7}
        textAlign="left"
        maxWidth="1400px"
        sx={{
          [theme.breakpoints.down('xl')]: {
            maxWidth: '1000px',
          },
        }}
      >
        <img src={Img} alt="Logo" width="100%" />
      </Box>
    </Box>
  </Box>
);
