import { Box } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import Lottie from 'react-lottie-player';
import Logo from '../../assets/images/darkLogo.svg';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const SavesCO2 = () => {
  const [isActiveSession] = useActiveSession('#SavesCO2');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={6} marginTop={6} marginBottom={9}>
        <img src={Logo} alt="Jobrad Logo" />
      </Box>
      <Box paddingLeft={10}>
        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>

        <Box position="relative" right={4.5} marginTop={9}>
          <Typography
            color="primary.dark"
            variant="h1"
            sx={{ marginBottom: '48px' }}
          >
            {isActiveSession ? (
              <CountUp start={0} end={276} duration={2} />
            ) : (
              '276'
            )}
            kg
          </Typography>
        </Box>

        <Typography variant="h5" color="primary.dark">
          CO<sup>2</sup> sparen Gelegenheitsradler:innen pro
        </Typography>

        <Typography variant="h5" color="primary.dark">
          Jahr im Vergleich zum Auto.
        </Typography>
      </Box>
    </Box>
  );
};
