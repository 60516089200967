import ReactFullpage from '@fullpage/react-fullpage';
import { Box } from '@mui/material';
import React from 'react';
import Img from '../../../assets/images/hilke.jpg';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import { LowerCosts, ParkingLot, Quote, SavesCO2 } from '../../../sections';

const B2CTopic5 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={['SavesCO2', 'Quote', 'ParkingLot', 'LowerCosts', 'footer']}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <SavesCO2 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Quote
              image={Img}
              name="Hilke Patzwall"
              subtitle="CSR Managerin bei VAUDE"
              text={
                '„Pendelverkehr und Geschäftsreisen machen in der Klimabilanz von VAUDE fast die Hälfte der Emissionen aus. Mit JobRad® sind unsere Mitarbeiter seit 2015 klimafreundlich unterwegs und tun gleichzeitig viel für die eigene Gesundheit.”'
              }
              background="secondary.main"
            />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <ParkingLot />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <LowerCosts />
          </Box>

          <Box className="section">
            <Footer title="Mehr Dienstrad" url="/1#JobBikesSince2012" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic5;
