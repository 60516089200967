import React from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import Logo from '../../assets/images/whiteLogo.svg';
import Typography from '../../components/typography';
import PulsingButton from '../../components/pulsingButton';
import Lottie from 'react-lottie-player';
import Idle from '../../assets/lottie/Idle.json';
import IdleIPad from '../../assets/lottie/IdleIPad.json';

type Props = {
  action: Function;
};

const IdleMode = (props: Props) => (
  <Box
    height="100vh"
    overflow="hidden"
    sx={{ backgroundColor: theme.palette.primary.dark }}
  >
    <Box position="absolute" top="42px" left="36px" zIndex={99}>
      <img src={Logo} alt="Jobrad Logo" />
    </Box>

    <Box>
      <Lottie
        loop
        play
        animationData={window.innerWidth < 1920 ? IdleIPad : Idle}
        className="idleMode"
      />
    </Box>

    <Box position="absolute" width="100%" bottom="48px" zIndex={99}>
      <Box display="flex" justifyContent="center">
        <PulsingButton onClick={() => props.action()}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body1" color="primary.dark" fontWeight={700}>
              Los geht's!
            </Typography>
          </Box>
        </PulsingButton>
      </Box>
    </Box>
  </Box>
);

export default IdleMode;
