import { Box } from '@mui/material';
import Lottie from 'react-lottie-player';
import Logo from '../../assets/images/darkLogo.svg';
import Img from '../../assets/images/logosGroup.svg';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';

export const Logos = () => (
  <Box sx={{ backgroundColor: theme.palette.primary.main }}>
    <Box paddingLeft={6} marginTop={6} marginBottom={9}>
      <img src={Logo} alt="Jobrad Logo" />
    </Box>
    <Box position="absolute" height="100%" top="0" right="0">
      <Box display="flex" alignItems="center" height="100%">
        <Lottie loop play animationData={DragIndicator} />
      </Box>
    </Box>
    <Box margin="90px 150px">
      <Box
        maxWidth="1000px"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h5"
          color="primary.dark"
          fontWeight={700}
          sx={{ marginBottom: '24px' }}
        >
          Prägen statt mithalten.
        </Typography>

        <Typography variant="h6" color="primary.dark">
          Die JobRad Gruppe bringt neue Ideen rund um das Thema "Mobilität" nach
          vorne.
        </Typography>

        <Typography variant="h6" color="primary.dark">
          Geballte Innovationskraft für frische Ansätze und zukunftsweisende
          Lösungen.
        </Typography>
      </Box>

      <Box
        marginTop={7}
        textAlign="center"
        width="100%"
        sx={{
          [theme.breakpoints.up('md')]: { maxWidth: '1400px' },
          [theme.breakpoints.up('lg')]: { maxWidth: '1900px' },
        }}
      >
        <img src={Img} alt="Logos" style={{ width: '100%' }} />
      </Box>
    </Box>
  </Box>
);
