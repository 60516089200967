import ReactFullpage from '@fullpage/react-fullpage';
import { Box } from '@mui/material';
import React from 'react';
import Img from '../../../assets/images/ruth.jpg';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import {
  CompanyBikeLeasing,
  JobBikesSince2012,
  OrdersIn2022,
  Quote,
  Stationary,
} from '../../../sections';

const B2CTopic1 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'JobBikesSince2012',
      'CompanyBikeLeasing',
      'Quote',
      'OrdersIn2022',
      'Stationary',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <JobBikesSince2012 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <CompanyBikeLeasing />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <Quote
              image={Img}
              name="Dr. Ruth Schulze"
              subtitle="Director employment conditions bei Robert Bosch GmbH"
              text={
                '„Bei JobRad® haben wir von Anfang an gespürt, wie sehr man dort für das Thema brennt, die wollen nachhaltige Mobilität tatsächlich voranbringen.”'
              }
              background="primary.dark"
            />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <OrdersIn2022 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <Stationary />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <Footer title="Mehr Erfolgs-geschichten" url="/2#Logos" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic1;
