import ReactFullpage from '@fullpage/react-fullpage';
import { Box } from '@mui/material';
import React from 'react';
import Img from '../../../assets/images/martin.jpg';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import {
  BestProtection,
  Carefree,
  Employees,
  Impact,
  Quote,
} from '../../../sections';

const B2CTopic3 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'Impact',
      'Employees',
      'Carefree',
      'Quote',
      'BestProtection',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Impact />
          </Box>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Employees />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Carefree />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <Quote
              image={Img}
              name="Dr. Martin Schneider"
              subtitle="Leiter Controlling, Finanz- und Rechnungswesen bei SUPER RTL"
              text={
                '„Für uns war ein verlässlicher Partner wichtig – und gute Beratung. Wir wollten mit dem Marktführer zusammenarbeiten, weil wir den Eindruck hatten, dass dieser alle steuerlichen Fragen richtig geregelt hat. Ausserdem hilft das JobRad–Portal allen Beteiligten: Es vereinfacht den gesamten Prozess – Bestellung und die Abwicklung – deutlich.”'
              }
              background="primary.dark"
            />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <BestProtection />
          </Box>

          <Box className="section">
            <Footer title="Mehr Gesundheit" url="/4#SickDays" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic3;
