import { Box } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const OrdersIn2022 = () => {
  const [isActiveSession] = useActiveSession('#OrdersIn2022');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={10} marginTop={10}>
        <Box position="relative" right="24px" marginTop={9} marginBottom={6}>
          <Typography color="primary.dark" variant="h1">
            +
            {isActiveSession ? (
              <CountUp start={0} end={500} duration={2} separator="." />
            ) : (
              500
            )}
          </Typography>
        </Box>

        <Typography variant="h5" color="primary.dark">
          Bestellungen von über 500
        </Typography>
        <Typography variant="h5" color="primary.dark">
          verschiedenen Fahrradmarken in 2023.
        </Typography>
      </Box>
    </Box>
  );
};
