// @ts-nocheck
import { useCallback, useMemo } from 'react';
import Revealer from '../components/revealer/Revealer';

export const useRevelaer = () => {
  const revealer = useMemo(() => {
    const revealerOpts = {
      nmbLayers: 2,
      bgcolor: ['#FFBCDF', '#B0E572'],
      effect: 'anim--effect-2',
    };

    return new Revealer(revealerOpts);
  }, []);

  const reveal = useCallback((direction: string, onNavigate: () => void) => {
    const callbackTime = 750;

    revealer.reveal(direction, callbackTime, onNavigate);
  }, []);

  return reveal;
};
