import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import FullPage from '../pages/fullPage';

const RoutesList = () => (
  <Router>
    <Routes>
      <Route path="/:topic" element={<FullPage />} />
      <Route path="" element={<Navigate to="/1" />} />
    </Routes>
  </Router>
);

export default RoutesList;
