import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';

export const BestProtection = () => (
  <Box sx={{ backgroundColor: theme.palette.primary.main }}>
    <Box paddingLeft={10} marginTop={10}>
      <Typography variant="h2" color="primary.dark" fontWeight={700}>
        Beste Absicherung –
      </Typography>

      <Typography variant="h3" color="primary.dark">
        unser Ausfallmanagement
      </Typography>

      <Typography variant="h3" color="primary.dark">
        bietet stets flexible Lösungen
      </Typography>

      <Typography variant="h3" color="primary.dark">
        für Sie und Ihre
      </Typography>

      <Typography variant="h3" color="primary.dark">
        Mitarbeitenden.
      </Typography>
    </Box>
  </Box>
);
